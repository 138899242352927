var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      !_vm.isFilmSingle
        ? _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-select",
                        {
                          on: { change: _vm.handleChangeMenuSelected },
                          model: {
                            value: _vm.menuSelected,
                            callback: function ($$v) {
                              _vm.menuSelected = $$v
                            },
                            expression: "menuSelected",
                          },
                        },
                        [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: null } },
                            [_vm._v(_vm._s(_vm.FormMSG(43, "All")))]
                          ),
                          _vm._l(_vm.menus, function (menu, index) {
                            return _c(
                              "b-form-select-option",
                              { key: index, attrs: { value: menu.value } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(menu.text) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.listTsDays.length > 0
                ? _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-size": "13px",
                              color: "rgba(6, 38, 62, 0.65)",
                            },
                          },
                          [
                            _vm._v(
                              "* " +
                                _vm._s(
                                  _vm.FormMSG(
                                    38,
                                    "Select the Workdays you want to send for Validation."
                                  )
                                )
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listTsDays.length === 0
                ? _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c("b-col", { staticClass: "text-center" }, [
                        _c("p", [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(39, "No timesheets found")) +
                              "\n\t\t\t\t"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.listTsDays.length === 0
                ? _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c("b-col", { staticClass: "text-center" }, [
                        _c("p", [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(39, "No timesheets found")) +
                              "\n\t\t\t\t"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _c(
        "b-row",
        { staticClass: "mt-2", staticStyle: { "margin-bottom": "100px" } },
        [
          _c(
            "b-col",
            _vm._l(_vm.listTsDays, function (tsDay, i) {
              return _c(
                "div",
                {
                  staticStyle: {
                    "border-radius": "8px",
                    "background-color": "#f8f9fb",
                  },
                  style: `margin-top: ${i > 0 ? "15px" : "0"}; border: ${
                    tsDay.isSelected ? "1px solid #00A09C" : "none"
                  }`,
                },
                [
                  _c("div", { staticStyle: { padding: "16px" } }, [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      tsDay.validated === 0 && tsDay.isInContract
                        ? _c(
                            "div",
                            { staticStyle: { width: "10%" } },
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  value: true,
                                  "unchecked-value": false,
                                  size: "lg",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChangeTsDaySelected(
                                      $event,
                                      tsDay
                                    )
                                  },
                                },
                                model: {
                                  value: tsDay.isSelected,
                                  callback: function ($$v) {
                                    _vm.$set(tsDay, "isSelected", $$v)
                                  },
                                  expression: "tsDay.isSelected",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          style: `width: ${
                            tsDay.validated === 0 ? "90%" : "100%"
                          }`,
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "fw-400",
                                    staticStyle: {
                                      color: "#06263ed6",
                                      "font-size": "13px",
                                      "margin-top": "4px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.getDate(tsDay.date)) +
                                        "\n\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]),
                              _c("b-col", { attrs: { cols: "6" } }, [
                                !_vm.isFilmSingle
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "wrap-status d-flex justify-content-end",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: `status ${_vm.classStatus(
                                              tsDay.validated
                                            )}`,
                                            staticStyle: {
                                              "font-size": "0.795rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(tsDay.validatedStatus) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticStyle: { "margin-top": "4px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fw-600 m-0",
                                      staticStyle: { "font-size": "16px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.getCurrency(tsDay.salary)
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-content-start align-items-center pt-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "info-time-co2" },
                                [
                                  _c("Clock10", {
                                    attrs: { color: "#06263ED6", size: 16 },
                                  }),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.getHhString(tsDay.hhmm)) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "info-time-co2 ml-2" },
                                [
                                  _c("Sprout", {
                                    attrs: { color: "#00A09C", size: 16 },
                                  }),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.getCO2(tsDay.kgCoTwo)) +
                                      "  CO"
                                  ),
                                  _c("sub", [_vm._v("2")]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("b-collapse", { attrs: { id: `collapse-${tsDay.id}` } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-left": "16px !important",
                          "padding-right": "16px !important",
                        },
                      },
                      [
                        _c("CardListBuilder", {
                          attrs: {
                            "style-mode": "table",
                            items: _vm.changeToArray(tsDay),
                            fields: _vm.tsfieldsMobile,
                            "collapsed-fields": _vm.tsfieldsCollapseMobile,
                            "toggle-filter-options": _vm.tsfieldsFilterOptions,
                            hideStatus: true,
                            showFilter: false,
                            "hide-filter-options": "",
                            styleMode: "list",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "totPerDiem",
                                fn: function (data) {
                                  return _vm.showLunchPerDiem(data.item) ||
                                    _vm.showHotelPerDiem() ||
                                    _vm.showDinnerPerDiem() ||
                                    _vm.showAbroadPerDiem()
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end align-items-center",
                                          },
                                          [
                                            data.item.totPerDiem > 0
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.left.click",
                                                        modifiers: {
                                                          left: true,
                                                          click: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                    attrs: {
                                                      title:
                                                        _vm.tooltipContentPerIdem(
                                                          data.item
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon("Info"),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color: "#225CBD",
                                                          size: 16,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "2px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getCurrency(
                                                      data.item.totPerDiem
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : undefined
                                },
                              },
                              {
                                key: "totAllowances",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end align-items-center",
                                      },
                                      [
                                        data.item.totAllowances > 0
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.left.click",
                                                    modifiers: {
                                                      left: true,
                                                      click: true,
                                                    },
                                                  },
                                                ],
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                attrs: {
                                                  title:
                                                    _vm.tooltipContentAllowance(
                                                      data.item
                                                    ),
                                                },
                                              },
                                              [
                                                _c(_vm.getLucideIcon("Info"), {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#225CBD",
                                                    size: 16,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "2px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getCurrency(
                                                  data.item.totAllowances
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "mt-1",
                      staticStyle: {
                        padding: "10px 16px",
                        "border-top": "2px solid white",
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "5" } }, [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: `collapse-${tsDay.id}`,
                                    expression: "`collapse-${tsDay.id}`",
                                  },
                                ],
                                staticClass:
                                  "btn-transparent color-blue fsz-13 flex-row justify-content-end align-items-center",
                                on: {
                                  click: function ($event) {
                                    tsDay.showDetails = !tsDay.showDetails
                                  },
                                },
                              },
                              [
                                !tsDay.showDetails
                                  ? _c(
                                      "div",
                                      { staticClass: "fsz-13" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(33, "Show details")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                        _c("ChevronDown", {
                                          attrs: { color: "#225CBD", size: 15 },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "fsz-13" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(34, "Hide details")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                        _c("ChevronUp", {
                                          attrs: { color: "#225CBD", size: 15 },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ]),
                          _c("b-col", { attrs: { cols: "7" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-center",
                              },
                              [
                                tsDay.validated !== 0 &&
                                tsDay.validated !== 16 &&
                                tsDay.validated !== 4 &&
                                !_vm.isFilmSingle
                                  ? _c("div", {}, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent fsz-13 d-flex flex-row justify-content-end align-items-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleClickEditTsDay(
                                                tsDay
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "2px",
                                                "margin-right": "4px",
                                              },
                                            },
                                            [
                                              _c("Eye", {
                                                attrs: {
                                                  color: "#06263ED6",
                                                  size: 14,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.FormMSG(85, "View"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end align-items-center",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn-transparent fsz-13 mr-2 d-flex flex-row justify-content-end align-items-center",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickEditTsDay(
                                                  tsDay
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "2px",
                                                  "margin-right": "4px",
                                                },
                                              },
                                              [
                                                _c("Edit", {
                                                  attrs: {
                                                    color: "#06263ED6",
                                                    size: 14,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(32, "Edit"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn-transparent fsz-13 d-flex flex-row justify-content-end align-items-center",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickDeleteTsDay(
                                                  tsDay
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "2px",
                                                  "margin-right": "4px",
                                                },
                                              },
                                              [
                                                _c("Trash2", {
                                                  attrs: {
                                                    color: "#06263ED6",
                                                    size: 14,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(16, "Delete")
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      !_vm.isFilmSingle && _vm.hasNotSubmitted
        ? _c(
            "b-row",
            { staticClass: "footer-fixed", staticStyle: { "z-index": "50" } },
            [
              _c(
                "b-col",
                { staticClass: "mt-2", attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "700",
                      },
                      attrs: {
                        variant: "primary",
                        block: "",
                        disabled: !_vm.tsDaysSelected.length > 0,
                      },
                      on: { click: _vm.handleClickSendToValidation },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(45, "Send to validation")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mt-2",
                  staticStyle: { "font-size": "14px", "font-weight": "700" },
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-primary",
                        block: "",
                        disabled: !_vm.hasNotSubmitted,
                      },
                      on: { click: _vm.handleClickSelectAll },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(41, "Select all")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }