<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div v-if="$screen.width <= 567">
				<div class="mobile-container animated fadeIn" style="margin-left: -16px; margin-right: -16px; background: white">
					<!-- position: absolute; left: 0; right: 0; -->
					<b-row>
						<b-col class="clearfix mb-1">
							<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">{{ FormMSG(10, 'My work') }} {{ '(' + currentMonth + ')' }}</h1>
						</b-col>
					</b-row>
					<div class="content-detailsMyTimesheet-mobile">
						<b-row style="cursor: pointer; margin-left: 1px" @click="$router.go(-1)">
							<b-col cols="1" style="padding-top: 2px">
								<ArrowLeft color="#06263E" :size="$screen.width < 411 ? 18 : 25" />
							</b-col>
							<b-col cols="10">
								<h3 class="mt-1">
									<b>{{ FormMSG(14201, 'Back to timesheets') }}</b>
								</h3>
							</b-col>
						</b-row>
						<form-time-card
							use-form-for="mobile"
							:time-cards="TsDays"
							:time-card-to-edit="currDayToEdit"
							:user-id="currUserId"
							@form-time-card:save-cur-day-success="reloadData"
							@form-time-card:udpate-date="reloadData"
						/>
					</div>
					<!-- <b-tabs :value="tabMobileValue" @input="tabMobileValue = $event">
						<b-tab :title="FormMSG(1246, 'Add workday')">
							
						</b-tab>
						<b-tab>
							<template #title>
								<div class="d-flex flex-row justify-content-center align-items-center">
									<div>{{ FormMSG(1245, 'My workdays') }}</div>
									<b-badge pill variant="secondary" class="custom-badge ml-2">
										{{ TsDays.length }}
									</b-badge>
								</div>
							</template>
							<work-days
								:ts-days="TsDays"
								@work-days:delete-timesheet="delItem"
								@work-days:edit-timesheet="handleWorkDaysEditTimesheet"
								@work-days:send-to-validation="handleWorkDaysSendToValidation"
							/>
						</b-tab>
					</b-tabs> -->
				</div>
			</div>
			<div class="container-layout px-0" v-else>
				<b-row v-if="TsDays">
					<b-col cols="12" xl="12">
						<transition name="slide">
							<!-- bg-variant="secondary" text-variant="white" -->
							<div class="form">
								<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(10, 'My work') }}</h1>
								<form-time-card
									use-form-for="web"
									:time-cards="TsDays"
									:time-card-to-edit="currDayToEdit"
									:user-id="currUserId"
									@form-time-card:save-cur-day-success="reloadData"
									@form-time-card:udpate-date="reloadData"
								/>
								<b-card no-body class="not-submited-days-container">
									<b-card-body>
										<b-row>
											<b-col cols="12" xl="12">
												<div>
													<h2>3 - {{ FormMSG(38, 'Days of ') }} {{ getMonthAndYear(currDayDate) }}</h2>
												</div>
												<div v-if="canSeeSalary" class="lh-20">
													<b-form-checkbox class="pj-cb pb-1" id="showGridDetails" v-model="showGridDetails" size="lg">
														{{ FormMSG(1224, 'Show salary details') }}
													</b-form-checkbox>
												</div>
												<div v-if="getRowCount(TsDays) > 0" class="pt-2">
													<p>
														{{ FormMSG(37, 'Click on a row to view a day. You can modify not submitted and refused days.') }}
													</p>
													<CardListBuilder
														v-if="$screen.width < 992"
														style-mode="table"
														:items="TsDays"
														:fields="tsFieldsMobile"
														:collapsed-fields="tsFieldsCollapseMobile"
														:toggle-filter-options="tsFieldsFilterOptions"
														:hideStatus="isFilmSingle"
													>
														<template slot="actions" slot-scope="{ item, showCollapseDetails }">
															<b-button variant="primary" size="sm" @click="showCollapseDetails(item)">
																<i class="icon-eye"></i>
															</b-button>
															<b-button
																v-if="isFilmSingle || item.validated == 0 || item.validated == 4 || item.validated == 16"
																variant="none"
																size="sm"
																@click="editMobileItem(item)"
															>
																<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
															</b-button>

															<b-button
																v-if="isFilmSingle || item.validated == 0 || item.validated == 4 || item.validated == 16"
																variant="none"
																size="sm"
																@click="delItem(item.id)"
															>
																<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
															</b-button>
														</template>
														<template slot="info" slot-scope="data">
															{{ data.item.date | frDate }}
														</template>
													</CardListBuilder>
													<b-row class="mt-3 mb-3" v-if="showSelectAll">
														<b-col>
															<b-button variant="success" class="mr-2" @click="handleClickSelectAll">
																{{ FormMSG(254, 'Select all') }}
															</b-button>
														</b-col>
													</b-row>
													<b-table
														v-if="$screen.width >= 992"
														ref="myTable"
														:selected-variant="selectedColor"
														:hover="hover"
														responsive="sm"
														selectable
														:select-mode="selectMode"
														:items="TsDays"
														style="text-align: center"
														:fields="tsFields"
														:current-page="currentPage"
														:per-page="perPage"
														:head-variant="hv"
														bordered
														small
														@row-clicked="rowClicked"
													>
														<template #cell(isSelected)="data">
															<span>
																<b-form-checkbox
																	v-if="data.item.validated === 0 && data.item.isInContract"
																	:id="`ts-day-selected-${data.item.id}`"
																	:name="`tsDaySelected${data.item.id}`"
																	v-model="data.item.isSelected"
																	@change="handleChangeTsDaySelected($event, data.item)"
																	size="lg"
																	class="pj-cb pb-1"
																/>
															</span>
														</template>
														<template #cell(dayType)="data">
															<span v-html="data.value" />
														</template>
														<template #cell(encoder)="data">
															<div class="d-flex flex-row align-items-center justify-content-center">
																<div>{{ encodedBy(data.item) }}</div>
															</div>
														</template>
														<template #cell(hhmm)="data">
															<span v-html="data.value" />
														</template>
														<template #cell(hhmmOvt)="data">
															<span v-html="data.value" />
														</template>
														<template #cell(hhmmOvt1)="data">
															<span v-html="data.value" />
														</template>
														<template #cell(hhmmOvt2)="data">
															<span v-html="data.value" />
														</template>
														<template #cell(hhmmTransport)="data">
															<span v-html="data.value" />
														</template>
														<template #cell(hhmmNight)="data">
															<span v-html="data.value" />
														</template>
														<template #cell(hhmmTooEarly)="data">
															<span v-html="data.value" />
														</template>
														<template
															#cell(lunch)="data"
															v-if="showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()"
														>
															<div class="d-flex justify-content-center align-items-center">
																<button
																	v-if="data.item.totPerDiem > 0"
																	class="btn-transparent text-color-rhapsody-in-blue"
																	v-b-tooltip.hover.left.html="tooltipContentPerIdem(data.item)"
																>
																	<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="20" />
																</button>
																<div class="adjust-icon-ts-tsd">{{ getCurrency(data.item.totPerDiem) }}</div>
															</div>
														</template>
														<template #cell(hotel)="data">
															<div class="d-flex justify-content-center align-items-center">
																<button
																	v-if="data.item.totAllowances > 0"
																	class="btn-transparent text-color-rhapsody-in-blue"
																	v-b-tooltip.hover.left.html="tooltipContentAllowance(data.item)"
																>
																	<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
																</button>
																<div class="adjust-icon-ts-tsd">
																	{{ getCurrency(data.item.totAllowances) }}
																</div>
															</div>
														</template>
														<template #cell(validatedStatus)="data">
															<div class="wrap-status" v-if="!isFilmSingle">
																<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
																	{{ data.item.validatedStatus }}
																</div>
															</div>
														</template>
														<!-- <template slot="rem" slot-scope="data"> -->
														<template #cell(rem)="data">
															<div class="d-flex justify-content-center align-items-center">
																<button
																	v-if="
																		isFilmSingle ||
																		data.item.validated == 0 ||
																		data.item.validated == 4 ||
																		data.item.validated == 16
																	"
																	class="btn-transparent text-color-rhapsody-in-blue"
																>
																	<component
																		:is="getLucideIcon(ICONS.TRASH.name)"
																		:color="ICONS.TRASH.color"
																		:size="20"
																		@click="delItem(data.item.id)"
																	/>
																</button>
															</div>
															<!-- <v-icon>icon-close</v-icon> -->
														</template>
													</b-table>
												</div>
												<div v-if="getRowCount(TsDays) < 1" class="empty-state">
													{{ FormMSG(42, 'No timesheet to send') }}
												</div>
											</b-col>
										</b-row>
										<nav v-if="getRowCount(TsDays) > 0" class="d-flex justify-content-end">
											<b-row v-if="!isFilmSingle">
												<b-col xl="12" class="clearfix valid-days-mobile-floating-button">
													<b-button
														size="md"
														variant="success"
														v-if="!disableSendData()"
														@click="SendDataForValidation"
														:disabled="tsDaysSelected.length === 0"
														:class="`${$screen.width < 768 ? 'w-100' : ''}`"
													>
														{{ FormMSG(43, 'Send not submitted days for validation') }}
													</b-button>
												</b-col>
											</b-row>
										</nav>
									</b-card-body>
								</b-card>
							</div>
						</transition>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag';
import moment from 'moment';
import { isNil } from '@/shared/utils';
import { store } from '@/store';
import { rendCurrency, rendKgCo2 } from '~helpers';
import StartEndTimeOverRange from '@/components/StartEndTimeOverRange';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import CalendarWork from '@/components/Work/CalendarWork';
import { getLangISOByIndex } from '@/helpers/translate';
import WorkDays from '@/components/Work/WorkDays';
import { myContracts } from '@/cruds/contract.crud';
import { classStatus } from '@/shared/global-status';
import { ArrowLeft, ChevronDown, ChevronUp, Info } from 'lucide-vue';
import { getTsDays, saveTsDay, submitSelectedTimesheet, delTimesheet } from '@/cruds/timesheet.crud';
import FormTimeCard from '@/components/Work/FormTimeCard';

export default {
	name: 'Work',
	components: { Calendar, StartEndTimeOverRange, CalendarWork, WorkDays, ChevronDown, ChevronUp, Info, FormTimeCard, ArrowLeft },
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],

	data() {
		return {
			hv: 'dark',
			hover: true,
			Validated: 0,
			showGridDetails: false,
			erreur: {},
			contract: {
				defaultDayType: 0,
				hotelPerDiem: 0,
				lunchPerDiem: 0,
				lunchMinimum: 60,
				totMinPerDay: 480 // DEFAULT 8 HOUR WORK
			},
			myContracts: [],
			curMonthStr: `${moment().format('YYYY')}-${moment().format('MM')}`,
			TsDays: [],
			currentPage: 1,
			perPage: 0, // anciennement 31, 0 pour retrait de pagination
			warning: '',
			successModal: false,
			successModalDelete: false,
			filter: '',
			selectedColor: 'primary',
			selectMode: 'single',
			disableSaveButton: false,
			originalCurDay: {},
			newDaySelected: false,
			curDay: {
				id: 0,
				date: moment().format('YYYY-MM-DD') + 'T00:00:00Z',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				leftHomeAt: 'T07:00:00Z',
				returnedHomeAt: 'T19:00:00Z',
				totMin: 0,
				notSpecifiedLunch: false,
				lunchStrTime: 'T12:00:00Z',
				lunchEndTime: 'T13:00:00Z',
				lunch: false,
				hotel: false,
				useDinner: false,
				useAbroad: false,
				stageDayUsed: false,
				dayType: 0,
				validated: 0,
				kgCoTwo: 0,
				validatedStatus: 'Not Submited',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: '',
				statusClass: 'not-submitted',
				useMileageToSet: false,
				transportFromHomeToWork: false
			},
			newCurDay: {
				id: 0,
				date: '2019-09-01',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				leftHomeAt: 'T07:00:00Z',
				returnedHomeAt: 'T19:00:00Z',
				totMin: 0,
				notSpecifiedLunch: true,
				lunchStrTime: 'T12:00:00Z',
				lunchEndTime: 'T13:00:00Z',
				lunch: false,
				hotel: false,
				useDinner: false,
				useAbroad: false,
				stageDayUsed: false,
				dayType: 0,
				validated: 0,
				kgCoTwo: 0,
				validatedStatus: 'Not Submited',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: '',
				statusClass: 'not-submitted',
				useMileageToSet: false,
				transportFromHomeToWork: false
			},
			activeView: 'day',
			accordionActive: null,
			isUpdate: false,
			tsDaysSelected: [],
			tabMobileValue: 0,

			currentMonth: '',
			valueCurDayFromDb: false,
			currDayDate: '',

			currDayToEdit: null
		};
	},
	computed: {
		canSeeSalary() {
			return !store.state.myProject.hideSalaryInfo || store.canViewGlobalBudget();
		},
		currUserId() {
			return store.userID();
		},
		showSelectAll() {
			let result = false;
			for (let i = 0; i < this.TsDays.length; i++) {
				const tsDay = this.TsDays[i];
				if (tsDay.validated === 0 || tsDay.validated === 4 || tsDay.validated === 16) {
					result = true;
				}
			}
			return result;
		},
		tsFields() {
			let flds = [];
			flds.push({
				key: 'isSelected',
				label: this.FormMSG(351, 'Selected'),
				formatter: (v) => v
			});
			const statusFld = {
				key: 'validatedStatus',
				label: this.FormMSG(50, 'Status'),
				sortable: true
			};
			// console.log("isfilmsingle:",this.isFilmSingle)
			if (!this.isFilmSingle) {
				flds.push(statusFld);
			}
			const fldsBase = [
				{
					key: 'date',
					label: this.FormMSG(51, 'Date'),
					formatter: (v) => v.substring(8, 10),
					sortable: true
				},
				{
					key: 'strTime',
					label: this.FormMSG(52, 'Start'),
					// formatter: v => v.split('T')[1].substring(0, 5),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';

						// if (root.strEndNotSpecified === false) {
						// 	const time = root.strTime.split('T')[1].substring(0, 5);
						// 	return root.strTime.substring(8, 10) == root.date.substring(8, 10) ? time : time + ' (' + root.strTime.substring(8, 10) + ')';
						// } else {
						// 	return '--:--';
						// }

						if (!root.dayIsNotWorkHoliday) {
							if (root.strEndNotSpecified === false) {
								const time = root.strTime.split('T')[1].substring(0, 5);
								return root.strTime.substring(8, 10) === root.date.substring(8, 10) ? time : time + ' (' + root.strTime.substring(8, 10) + ')';
							} else {
								return '--:--';
							}
						}
					},
					sortable: false
				},
				{
					key: 'endTime',
					label: this.FormMSG(53, 'End'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';

						// if (root.strEndNotSpecified === false) {
						// 	const strDay = new Date(root.strTime).getDay();
						// 	const endDay = new Date(root.endTime).getDay();
						// 	const time = root.endTime.split('T')[1].substring(0, 5);
						// 	return root.endTime.substring(8, 10) == root.date.substring(8, 10) ? time : time + ' (' + root.endTime.substring(8, 10) + ')';
						// } else {
						// 	return '--:--';
						// }

						if (!root.dayIsNotWorkHoliday) {
							if (root.strEndNotSpecified === false) {
								// const strDay = new Date(root.strTime).getDay();
								// const endDay = new Date(root.endTime).getDay();
								const time = root.endTime.split('T')[1].substring(0, 5);
								return root.endTime.substring(8, 10) === root.date.substring(8, 10) ? time : time + ' (' + root.endTime.substring(8, 10) + ')';
							} else {
								return '--:--';
							}
						}
					},
					sortable: false
				},
				{
					key: 'dayType',
					label: this.FormMSG(54, 'Type'),
					formatter: (value, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return this.getFieldValueByShowGridDetails(this.showGridDetails, value, root.salaryBase);
					},
					sortable: false
				},
				{
					key: 'hhmm',
					label: this.FormMSG(55, 'Hours'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						return !this.showGridDetails ? root.hhmm : root.hhmm;
					},
					sortable: false
				}
			];

			flds = flds.concat(fldsBase);
			//console.log("flds",flds)
			const ovtFld = {
				key: 'hhmmOvt',
				label: this.FormMSG(56, 'Ovt'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt : root.hhmmOvt + separator + rendCurrency(root.salaryOvertime);
				}
			};
			if (this.showOvt()) {
				flds.push(ovtFld);
			}

			const ovt1Fld = {
				key: 'hhmmOvt1',
				label: this.FormMSG(156, 'Ovt1'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt1 : root.hhmmOvt1 + separator + rendCurrency(root.salaryOvertime1);
				}
			};
			if (this.showOvt1()) {
				flds.push(ovt1Fld);
			}
			const ovt2Fld = {
				key: 'hhmmOvt2',
				label: this.FormMSG(157, 'Ovt2'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt2 : root.hhmmOvt2 + separator + rendCurrency(root.salaryOvertime2);
				}
			};
			if (this.showOvt2()) {
				flds.push(ovt2Fld);
			}

			const transportFld = {
				key: 'hhmmTransport',
				label: this.FormMSG(158, 'Transport'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTransport : root.hhmmTransport + separator + rendCurrency(root.salaryTransport);
				}
			};
			if (this.showTransport()) {
				flds.push(transportFld);
			}

			const nightFld = {
				key: 'hhmmNight',
				label: this.FormMSG(57, 'Night'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmNight : root.hhmmNight + separator + rendCurrency(root.salaryHourNight);
				}
			};
			if (this.showNightHours()) {
				flds.push(nightFld);
			}

			const tooEarlyHoursFld = {
				key: 'hhmmTooEarly',
				label: this.FormMSG(58, 'Anticipated'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTooEarly : root.hhmmTooEarly + separator + rendCurrency(root.salaryHourTooEarly);
				}
			};
			if (this.showTooEarlyHours()) {
				flds.push(tooEarlyHoursFld);
			}

			const beforeCallTime = {
				key: 'beforeCallTime',
				label: this.FormMSG(681, 'Precall'),
				sortable: false
			};

			if (this.showPreCall) {
				flds.push(beforeCallTime);
			}

			const lunchflds = [
				{
					key: 'lunchStrTime',
					label: this.FormMSG(60, 'Lunch start'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchStrTime.split('T')[1].substring(0, 5);
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchStrTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchStrTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'lunchEndTime',
					label: this.FormMSG(65, 'Lunch end'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchEndTime.split('T')[1].substring(0, 5);
						//console.log("in formatter:",root)
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchEndTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchEndTime.substring(8, 10) + ')';
					},
					sortable: false
				}
			];
			if (this.showLunch()) {
				flds = flds.concat(lunchflds);
			}
			const lunchPd = {
				key: 'lunch',
				label: this.FormMSG(610, 'Per Diem'),
				sortable: false
			};
			if (this.showLunchPerDiem()) {
				flds.push(lunchPd);
			}
			const HotelPd = {
				key: 'hotel',
				label: this.FormMSG(620, 'Allowances'),
				sortable: false
			};
			if (this.showHotelPerDiem()) {
				flds.push(HotelPd);
			}
			/**/
			const flds1 = [
				{
					key: 'dayRate',
					label: this.FormMSG(59, 'Rate'),
					formatter: (value) => {
						return value + ' %';
					},
					sortable: false
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true
				}
			];
			if (!store.state.myProject.hideSalaryInfo || store.canViewGlobalBudget()) {
				flds1.push(
					{
						key: 'salary',
						label: this.FormMSG(63, 'Salary'),
						formatter: (value) => rendCurrency(value),
						sortable: false
					},
					{
						key: 'rem',
						label: this.FormMSG(64, 'Remove'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center'
					}
				);
			} else {
				flds1.push({
					key: 'rem',
					label: this.FormMSG(64, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				});
			}
			flds = flds.concat(flds1);
			const encodedByField = () => {
				if (!this.isFilmSingle) {
					return { key: 'encoder', label: this.FormMSG(70, 'Encoded By'), sortable: false, class: 'text-center' };
				}
			};
			let arrays = [...flds.slice(0, 5), encodedByField(), ...flds.slice(5)].filter((item) => item !== undefined);
			return arrays;
		},
		tsFieldsMobile() {
			let fields = [
				{
					key: 'date',
					label: this.FormMSG(51, 'Date'),
					formatter: (v) => moment(v).format('DD/MM/YYYY'),
					sortable: true
				},
				{
					key: 'strTime',
					label: this.FormMSG(52, 'Start'),
					formatter: (v) => v.split('T')[1].substring(0, 5),
					sortable: false
				},
				{
					key: 'endTime',
					label: this.FormMSG(53, 'End'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const strDay = new Date(root.strTime).getDay();
						const endDay = new Date(root.endTime).getDay();
						const time = root.endTime.split('T')[1].substring(0, 5);
						return root.endTime.substring(8, 10) == root.strTime.substring(8, 10) ? time : time + ' (' + root.endTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'dayType',
					label: this.FormMSG(54, 'Type'),
					formatter: (value, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return this.getFieldValueByShowGridDetails(this.showGridDetails, value, root.salaryBase);
					},
					sortable: false
				},
				{
					key: 'hhmmOvt',
					label: this.FormMSG(169, 'Overtime'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmOvt : root.hhmmOvt + separator + rendCurrency(root.salaryOvertime);
					}
				},
				{
					key: 'hhmmOvt1',
					label: this.FormMSG(170, 'Overtime 1'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmOvt1 : root.hhmmOvt1 + separator + rendCurrency(root.salaryOvertime1);
					}
				},
				{
					key: 'hhmmOvt2',
					label: this.FormMSG(171, 'Overtime 2'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmOvt2 : root.hhmmOvt2 + separator + rendCurrency(root.salaryOvertime2);
					}
				},
				{
					key: 'hhmmTransport',
					label: this.FormMSG(158, 'Transport'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmTransport : root.hhmmTransport + separator + rendCurrency(root.salaryTransport);
					}
				},
				{
					key: 'hhmmNight',
					label: this.FormMSG(57, 'Night'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmNight : root.hhmmNight + separator + rendCurrency(root.salaryHourNight);
					}
				}
			];

			const tooEarlyHoursFld = {
				key: 'hhmmTooEarly',
				label: this.FormMSG(58, 'Anticipated'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTooEarly : root.hhmmTooEarly + separator + rendCurrency(root.salaryHourTooEarly);
				}
			};
			if (this.showTooEarlyHours()) {
				fields.push(tooEarlyHoursFld);
			}

			const lunchflds = [
				{
					key: 'lunchStrTime',
					label: this.FormMSG(60, 'Lunch start'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchStrTime.split('T')[1].substring(0, 5);
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchStrTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchStrTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'lunchEndTime',
					label: this.FormMSG(65, 'Lunch end'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchEndTime.split('T')[1].substring(0, 5);
						//console.log("in formatter:",root)
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchEndTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchEndTime.substring(8, 10) + ')';
					},
					sortable: false
				}
			];
			if (this.showLunch()) {
				fields = fields.concat(lunchflds);
			}

			fields.push({
				key: 'lunch',
				label: this.FormMSG(610, 'Per Diem'),
				sortable: false
			});

			fields.push({
				key: 'hotel',
				label: this.FormMSG(620, 'Allowances'),
				sortable: false
			});

			fields.push({
				key: 'dayRate',
				label: this.FormMSG(59, 'Rate'),
				formatter: (value) => {
					return value + ' %';
				},
				sortable: false
			});

			return fields;
		},
		tsFieldsCollapseMobile() {
			const keys = ['salary', 'hhmm', 'kgCoTwo', 'rem', 'validatedStatus'];
			return this.tsFields.filter((t) => !keys.includes(t.key));
		},
		tsFieldsFilterOptions() {
			return this.isFilmSingle
				? {}
				: {
						key: 'validated',
						choices: [
							{
								value: 'all',
								text: this.FormMSG(8009777, 'All')
							},
							{
								value: 0,
								text: this.FormMSG(897977, 'Not Submitted')
							},
							{
								value: 8,
								text: this.FormMSG(8977, 'Validated')
							}
						]
				  };
		},
		showPreCall() {
			return store.state.myProject.useBeforeCallTime;
		}
		// allowTimesheeyEntryOutOfContract() {
		// 	return store.state.myProject.allowTSEntryOutsideContract;
		// }
	},
	watch: {
		'$screen.width': {
			handler(val) {
				if (val <= 567) {
					this.showGridDetails = true;
				}
			},
			immediate: true
		}
	},
	async created() {
		// console.log("LANGUAGE:",moment.locale(getLangISOByIndex(store.appLanguage()).two))

		this.$nextTick(async () => {
			moment.locale(getLangISOByIndex(store.appLanguage()).two);
			this.currentMonth = moment().format('MMMM');
			this.myContracts = await myContracts();
			if (this.curDay.date.length === 0) {
				this.firstTimeInitialisation();
			}

			await this.reloadData({
				currMonthStr: `${moment().format('YYYY')}-${moment().format('MM')}`,
				currDayDate: moment(new Date()).format('YYYY-MM-DD')
			});
		});
	},
	methods: {
		encodedBy(data) {
			return `${data.encoder.name} ${data.encoder.firstName}`;
		},
		handleWorkDaysEditTimesheet(payload) {
			this.tabMobileValue = 0;
			const findIndex = this.TsDays.findIndex((TD) => TD.date.split('T')[0] === payload.id);
			this.currDayToEdit = this.TsDays[findIndex];
			// this.updateDate(payload.id);
			// this.setContractForDay(payload.id);
		},
		handleClickSelectAll() {
			this.tsDaysSelected = this.TsDays.filter((tsDay) => tsDay.validated === 0);

			this.TsDays = this.TsDays.map((tsDay) => ({ ...tsDay, isSelected: true }));
		},
		handleChangeTsDaySelected(payload, tsDay) {
			if (payload) {
				this.tsDaysSelected.push(tsDay);
			} else {
				this.tsDaysSelected = this.tsDaysSelected.filter((tDS) => tDS.id !== tsDay.id);
			}
		},
		showNightHours() {
			return store.state.myProject.useNightHours;
		},
		showTooEarlyHours() {
			return store.state.myProject.useTooEarlyHours;
		},
		showOvt() {
			return store.state.myProject.useOvertime;
		},
		showOvt1() {
			return store.state.myProject.useOvt1;
		},
		showOvt2() {
			return store.state.myProject.useOvt2;
		},
		showTransport() {
			//console.log("in show transport:",store.state.myProject);
			return store.state.myProject.useHomeToWorkTime;
		},
		showLunch() {
			return store.state.myProject.useLunchTime;
		},
		showLunchPerDiem() {
			return store.state.myProject.useLunchPerDiem && !this.curDay.stageDayUsed;
		},
		showHotelPerDiem() {
			return store.state.myProject.useHotelPerDiem;
		},
		showDinnerPerDiem() {
			return store.state.myProject.useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.state.myProject.useAbroadPerDiem;
		},
		/**
		 * @param {String|Number} itemId
		 */
		editMobileItem(item) {
			for (var i in this.TsDays) {
				var temp = parseInt(this.TsDays[i].id, 10);
				if (temp == item.id) {
					break;
				}
			}
			this.updateDate(item.date);
			this.$nextTick(() => {
				const el = this.$refs.workEditSection;
				// if (isNil(el)) return
				// el.scrollIntoView(true)
				// const scrolledY = window.scrollY
				// window.scrollTo({ top: scrolledY - 100, behavior: 'smooth' })
				this.$simpleScrollTo(el, { offset: 100 });
				this.newDaySelected = false;
			});
		},
		setContractForDay(date) {
			//console.log("in set contractForDay this.myContracts:",this.myContracts);
			if (!isNil(this.myContracts)) {
				for (var i = 0; i < this.myContracts.length; i++) {
					//console.log("in set contractForDay date:",date);
					//console.log("in set contractForDay date:",this.myContracts[i].startDate);
					if (date >= this.myContracts[i].startDate.substring(0, 10) && date <= this.myContracts[i].endDate.substring(0, 10)) {
						//console.log("in set contractForDay contract found:",this.myContracts[i]);
						this.contract = this.myContracts[i];
						return;
					}
				}
			}
			// console.log("in set contractForDay contract found:",this.myContracts[0]);
			if (!isNil(this.myContracts) && this.myContracts[0]) {
				this.contract = this.myContracts[0];
			}
		},
		initializeCurDay(date) {
			this.setContractForDay(date);
			this.curDay.strTime = moment(date).add(8, 'hours').format();
			this.curDay.endTime = this.initEndTimeByContract(date);
			this.curDay.leftHomeAt = moment(date).add(8, 'hours').format();
			this.curDay.returnedHomeAt = moment(date).add(17, 'hours').format();
			this.curDay.lunchStrTime = moment(date).add(12, 'hours').format();
			this.curDay.lunchEndTime = moment(date).add(13, 'hours').format();
			this.curDay.useStageDay = false;
		},
		initEndTimeByContract(date) {
			// const currStrTime = new Date(moment(date).add(8, 'hours').format());
			// const currEndTime = new Date(currStrTime.getTime() + 1000 * 60 * this.contract.totMinPerDay);
			// console.log('CURR END TIME', currEndTime);
			// alert('');
			const currStrTime = new Date(date + ' 08:00');
			return moment(currStrTime)
				.add(this.contract.totMinPerDay + this.contract.lunchMinimum, 'minutes')
				.format();
		},
		firstTimeInitialisation() {
			// console.log("firstTimeInitialisation my profile:",this.store)
			this.curDay.date = moment().format('YYYY-MM-DD');
			// console.log('this.curDay.date', this.curDay.date)
			this.initializeCurDay(this.curDay.date);
			this.curMonthStr = this.curDay.date.substring(0, 7);
		},
		updateTZdataEach(timeData) {
			this.setContractForDay(timeData.date);
			if (isNil(timeData)) return;
			// setting row color
			////console.log("timeData.endTime:",timeData.endTime);
			timeData.validatedStatus = this.validatedText(timeData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			timeData._rowVariant = ''; //this.validatedColor(timeData.validated);
			timeData.validatedClass = this.validatedColor(timeData.validated);
			timeData.statusClass = classStatus(timeData.validated);
			//console.log("timeData.strTime before:",timeData.strTime);
			// format to local time
			timeData.strTime = moment(timeData.strTime).format();
			timeData.endTime = moment(timeData.endTime).format();
			timeData.lunchStrTime = moment(timeData.lunchStrTime).format();
			timeData.lunchEndTime = moment(timeData.lunchEndTime).format();
			timeData.leftHomeAt = moment(timeData.leftHomeAt).format();
			timeData.returnedHomeAt = moment(timeData.returnedHomeAt).format();
			//console.log("timeData.strTime after:",timeData.strTime);
			timeData.hhmm = timeData.hours + ':' + timeData.minutes;
			timeData.hhmmOvt = timeData.hoursOvertime + ':' + timeData.minutesOvertime;
			timeData.hhmmOvt1 = timeData.hoursOvertime1 + ':' + timeData.minutesOvertime1;
			timeData.hhmmOvt2 = timeData.hoursOvertime2 + ':' + timeData.minutesOvertime2;
			timeData.hhmmTransport = timeData.hoursTransportTimePaid + ':' + timeData.minutesTransportTimePaid;
			timeData.hhmmNight = timeData.hoursNight + ':' + timeData.minutesNight;
			timeData.hhmmTooEarly = timeData.hoursTooEarly + ':' + timeData.minutesTooEarly;

			timeData.hotelPerDiem = timeData.contract.hotelPerDiem;
			timeData.lunchPerDiem = timeData.contract.lunchPerDiem;
			timeData.dinnerPerDiem = timeData.contract.dinnerPerDiem;
			timeData.abroadPerDiem = timeData.contract.abroadPerDiem;
			//console.log("this.contract:",this.contract)

			timeData.isSelected = false;
			timeData.showDetails = false;
			timeData.locationId = '' + timeData.location.id;
		},
		selectCurDay() {
			// check if curDay is in array of existing tsdays.
			// if yes, select it, if not, initialise a new curday with defaultvalues
			// look if chosen date is in tsdays, if yes get structure
			let foundCurDayInArray = false;

			////console.log("in selectCurDay begin", this.curDay.strTime);
			for (let i = 0; i < this.TsDays.length; i++) {
				let temp = this.TsDays[i].date;
				temp = temp.substring(0, 10);
				//    //console.log("temp:", temp);
				if (temp == this.curDay.date) {
					// fill curDay with tsTays Data
					//console.log("in selectCurDay found Before copy from tsdays[i]", this.curDay.strTime);
					this.curDay = { ...this.TsDays[i] };
					//console.log("in selectCurDay found after copy from tsdays[i]", this.curDay.strTime);
					if (this.curDay.stageDayUsed === 1) {
						this.curDay.stageDayUsed = true;
					}
					//    //console.log("in update day found line:", this.curDay.date)
					foundCurDayInArray = true;
					this.newDaySelected = false;
					this.setContractForDay(this.TsDays[i].date);
					this.originalCurDay = { ...this.curDay };

					this.checkSpecifyAtHome(this.TsDays[i]);

					this.valueCurDayFromDb = true;

					break;
				}
			}

			if (!foundCurDayInArray) {
				const tmpStr = this.curDay.date;
				this.newCurDay.validatedStatus = this.validatedText(this.newCurDay.validated);
				this.curDay = this.newCurDay;
				this.curDay.date = tmpStr;
				this.initializeCurDay(this.curDay.date);
				// contract found set defaultDayType according to this contract
				if (!isNil(this.myContracts) && this.myContracts.length > 0) {
					//console.log("this.contract.defaultDayType:",this.contract.defaultDayType)
					this.curDay.dayType = parseInt(this.contract.defaultDayType, 10);
				}
				this.newDaySelected = true;
				this.originalCurDay = {};
				// console.log('VUrday', this.curDay)
			}
		},
		validatedText(validated) {
			// returns the label text corresponding to the validated value from mixin function
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'info';
			}
			return retval;
		},
		async updateDate($event) {
			// suspend link between tsdays and curday until we check if new one or existing one
			this.curDay = this.newCurDay;
			// console.log("curday after new", this.curDay.date);
			// console.log("event in updateDate", $event);
			// receiving 2019-02-24
			this.curDay.date = $event;

			//console.log("in updateDate curdaydate= ", this.curDay.date);

			// check if month changed
			var curMStemp = $event.substring(0, 7);
			//console.log("in updateDate curMStemp= ",curMStemp);

			if (this.curMonthStr == curMStemp) {
				// in same month select day
				this.selectCurDay();
			} else {
				// need to load new month
				//console.log("need to reload:" ,curMStemp);
				this.curMonthStr = curMStemp;
				await this.reloadData();
			}
		},
		async reloadData({ currMonthStr, currDayDate }) {
			this.TsDays = [];
			this.curMonthStr = currMonthStr;

			this.currDayDate = currDayDate;
			var StartDate = this.curMonthStr + '-01T00:00:00+00:00';

			await getTsDays(StartDate)
				.then((result) => {
					if (result.length > 0) {
						let myTsDays = [...result];
						myTsDays.map(this.updateTZdataEach);
						this.TsDays = [...result];
					}
				})
				.catch((error) => {
					//console.log(error);
					this.erreur = error;
				});
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}, 500);
		},
		async delItem(id) {
			const action = async () => {
				var TsDayId = parseInt(id, 10);
				//console.log("in delItem:",TsDayId);
				await delTimesheet(TsDayId)
					.then(async (result) => {
						await this.reloadData({
							currMonthStr: this.curMonthStr,
							currDayDate: this.currDayDate
						});
						this.createToastForMobile(this.FormMSG(162, 'Success'), this.FormMSG(1610, 'Your days has been deleted'), '');
					})
					.catch((error) => {
						//console.log(error);
						this.erreur = error;
					});
			};
			this.confirmModal(action, this.FormMSG(1130, 'Do you really want to delete this time card?'));
		},
		getRowCount(items) {
			////console.log(items)
			return items.length;
		},
		getMonthAndYear(string) {
			return string.substring(5, 7) + '/' + string.substring(0, 4);
		},
		rowClicked(item) {
			// copy item content into curDay structure.
			this.currDayToEdit = item;
			// var temp;
			// for (var i in this.TsDays) {
			// 	temp = parseInt(this.TsDays[i].id, 10);
			// 	if (temp == item.id) {
			// 		this.checkSpecifyAtHome(this.TsDays[i]);
			// 		// fill curDay with tsTays Data
			// 		this.curDay = this.TsDays[i];
			// 		//console.log("rowclicked curday:", this.curDay);
			// 		if (this.curDay.stageDayUsed == 1) {
			// 			this.curDay.stageDayUsed = true;
			// 		}
			// 		this.originalCurDay = { ...this.curDay };
			// 		this.valueCurDayFromDb = true;
			// 		break;
			// 	}
			// }
		},
		disableSendData() {
			for (var i in this.TsDays) {
				if (this.TsDays[i].validated == 0) {
					return false;
					break;
				}
			}
			return true;
		},
		handleWorkDaysSendToValidation(payload) {
			this.tsDaysSelected = payload.tsDaysSelected;
			this.SendDataForValidation();
		},
		async SendDataForValidation() {
			const tsDaysToSend = this.prepareTsDaysSelectedToChange();

			await submitSelectedTimesheet(tsDaysToSend)
				.then(async (result) => {
					//console.log(result);
					// suspend link between tsdays and curday until we check if new one or existing one
					this.curDay = this.newCurDay;
					this.TsDays = [];
					// reload the Data
					await this.reloadData({
						currMonthStr: this.curMonthStr,
						currDayDate: this.currDayDate
					});
					// this.selectCurDay();
					var strTitle = this.FormMSG(162, 'Success');
					// this.$apollo.queries.TsDays.refetch()
					if (this.$screen.width <= 567) {
						this.createToastForMobile(this.FormMSG(161, 'Your days are submitted for validation'), '', '');
					} else {
						this.$bvToast.toast(this.FormMSG(161, 'Your days are submitted for validation'), {
							title: strTitle,
							variant: 'success',
							solid: true
						});
					}

					this.tsDaysSelected = [];
				})
				.catch((error) => {
					//console.log(error);
					this.erreur = error;
				});
		},
		prepareTsDaysSelectedToChange() {
			const result = [];
			for (let i = 0; i < this.tsDaysSelected.length; i++) {
				result.push(parseInt(this.tsDaysSelected[i].id));
			}
			return result;
		},
		tooltipContentPerIdem(item) {
			let content = [];

			if (this.showHotelPerDiem() && item.hotel && item.contract.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.getCurrency(item.contract.hotelPerDiem));
			}

			if (this.showLunchPerDiem() && item.lunch && item.contract.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.getCurrency(item.contract.lunchPerDiem));
			}

			if (this.showDinnerPerDiem() && item.useDinner && item.contract.dinnerPerDiem > 0) {
				content.push(this.FormMSG(212, 'Dinner: ') + this.getCurrency(item.contract.dinnerPerDiem));
			}

			if (this.showAbroadPerDiem() && item.useAbroad && item.contract.abroadPerDiem > 0) {
				content.push(this.FormMSG(211, 'Abroad: ') + this.getCurrency(item.contract.abroadPerDiem));
			}

			return content.join('<br /> ');
		},
		getCurrency(value) {
			return rendCurrency(value);
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(112, 'D');
			} else if (type == 1) {
				return this.FormMSG(113, 'W');
			} else if (type == 2) {
				return this.FormMSG(111, 'H');
			}
		},
		checkSpecifyAtHome(TsDay) {
			let strTimeToTimestamp = new Date(TsDay.strTime).getTime();
			let leftHomeAtToTimestamp = new Date(TsDay.leftHomeAt).getTime();
			let endTimeToTimestamp = new Date(TsDay.endTime).getTime();
			let returnedHomeAtToTimestamp = new Date(TsDay.returnedHomeAt).getTime();
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(110, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.getCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(114, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.getCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(115, 'Phone ') + '(' + this.rendAllowanceType(item.contract.phoneAllowanceRate) + '): ' + this.getCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(116, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.getCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(117, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.getCurrency(item.boxKitAllowance)
				);
			}

			if (item.mileageToSetAllowance > 0) {
				content.push(`${this.FormMSG(129, 'Mileage to set ')} : ${this.getCurrency(item.mileageToSetAllowance)}`);
			}

			return content.join('<br />');
		}
	}
};
</script>
<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

input[type='text'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	/*background-color: grey;
		color: white;*/
}
.clearfixx::after {
	content: '';
	clear: both;
	display: inline-block;
}
</style>
