var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.$screen.width <= 567
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "mobile-container animated fadeIn",
                  staticStyle: {
                    "margin-left": "-16px",
                    "margin-right": "-16px",
                    background: "white",
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "clearfix mb-1" }, [
                        _c(
                          "h1",
                          {
                            staticClass: "main-page-title",
                            class: { "is-pwa": _vm.$isPwa() },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.FormMSG(10, "My work")) +
                                " " +
                                _vm._s("(" + _vm.currentMonth + ")")
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "content-detailsMyTimesheet-mobile" },
                    [
                      _c(
                        "b-row",
                        {
                          staticStyle: {
                            cursor: "pointer",
                            "margin-left": "1px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [
                          _c(
                            "b-col",
                            {
                              staticStyle: { "padding-top": "2px" },
                              attrs: { cols: "1" },
                            },
                            [
                              _c("ArrowLeft", {
                                attrs: {
                                  color: "#06263E",
                                  size: _vm.$screen.width < 411 ? 18 : 25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("b-col", { attrs: { cols: "10" } }, [
                            _c("h3", { staticClass: "mt-1" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(14201, "Back to timesheets")
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("form-time-card", {
                        attrs: {
                          "use-form-for": "mobile",
                          "time-cards": _vm.TsDays,
                          "time-card-to-edit": _vm.currDayToEdit,
                          "user-id": _vm.currUserId,
                        },
                        on: {
                          "form-time-card:save-cur-day-success": _vm.reloadData,
                          "form-time-card:udpate-date": _vm.reloadData,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _c(
              "div",
              { staticClass: "container-layout px-0" },
              [
                _vm.TsDays
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", xl: "12" } },
                          [
                            _c("transition", { attrs: { name: "slide" } }, [
                              _c(
                                "div",
                                { staticClass: "form" },
                                [
                                  _c(
                                    "h1",
                                    {
                                      class: [
                                        `${
                                          !_vm.$screen.sm
                                            ? "main-page-title"
                                            : ""
                                        }`,
                                        { "is-pwa": _vm.$isPwa() },
                                      ],
                                    },
                                    [_vm._v(_vm._s(_vm.FormMSG(10, "My work")))]
                                  ),
                                  _c("form-time-card", {
                                    attrs: {
                                      "use-form-for": "web",
                                      "time-cards": _vm.TsDays,
                                      "time-card-to-edit": _vm.currDayToEdit,
                                      "user-id": _vm.currUserId,
                                    },
                                    on: {
                                      "form-time-card:save-cur-day-success":
                                        _vm.reloadData,
                                      "form-time-card:udpate-date":
                                        _vm.reloadData,
                                    },
                                  }),
                                  _c(
                                    "b-card",
                                    {
                                      staticClass:
                                        "not-submited-days-container",
                                      attrs: { "no-body": "" },
                                    },
                                    [
                                      _c(
                                        "b-card-body",
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    xl: "12",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c("h2", [
                                                      _vm._v(
                                                        "3 - " +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              38,
                                                              "Days of "
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.getMonthAndYear(
                                                              _vm.currDayDate
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm.canSeeSalary
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "lh-20",
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb pb-1",
                                                              attrs: {
                                                                id: "showGridDetails",
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.showGridDetails,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.showGridDetails =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "showGridDetails",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1224,
                                                                      "Show salary details"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.getRowCount(_vm.TsDays) >
                                                  0
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "pt-2" },
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    37,
                                                                    "Click on a row to view a day. You can modify not submitted and refused days."
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]),
                                                          _vm.$screen.width <
                                                          992
                                                            ? _c(
                                                                "CardListBuilder",
                                                                {
                                                                  attrs: {
                                                                    "style-mode":
                                                                      "table",
                                                                    items:
                                                                      _vm.TsDays,
                                                                    fields:
                                                                      _vm.tsFieldsMobile,
                                                                    "collapsed-fields":
                                                                      _vm.tsFieldsCollapseMobile,
                                                                    "toggle-filter-options":
                                                                      _vm.tsFieldsFilterOptions,
                                                                    hideStatus:
                                                                      _vm.isFilmSingle,
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "actions",
                                                                          fn: function ({
                                                                            item,
                                                                            showCollapseDetails,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "primary",
                                                                                      size: "sm",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return showCollapseDetails(
                                                                                          item
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "icon-eye",
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm.isFilmSingle ||
                                                                              item.validated ==
                                                                                0 ||
                                                                              item.validated ==
                                                                                4 ||
                                                                              item.validated ==
                                                                                16
                                                                                ? _c(
                                                                                    "b-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          variant:
                                                                                            "none",
                                                                                          size: "sm",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.editMobileItem(
                                                                                              item
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        _vm.getLucideIcon(
                                                                                          _vm
                                                                                            .ICONS
                                                                                            .EDIT
                                                                                            .name
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                _vm
                                                                                                  .ICONS
                                                                                                  .EDIT
                                                                                                  .color,
                                                                                              size: 20,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.isFilmSingle ||
                                                                              item.validated ==
                                                                                0 ||
                                                                              item.validated ==
                                                                                4 ||
                                                                              item.validated ==
                                                                                16
                                                                                ? _c(
                                                                                    "b-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          variant:
                                                                                            "none",
                                                                                          size: "sm",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.delItem(
                                                                                              item.id
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        _vm.getLucideIcon(
                                                                                          _vm
                                                                                            .ICONS
                                                                                            .TRASH
                                                                                            .name
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                _vm
                                                                                                  .ICONS
                                                                                                  .TRASH
                                                                                                  .color,
                                                                                              size: 20,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                        {
                                                                          key: "info",
                                                                          fn: function (
                                                                            data
                                                                          ) {
                                                                            return [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "frDate"
                                                                                    )(
                                                                                      data
                                                                                        .item
                                                                                        .date
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2000270877
                                                                    ),
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm.showSelectAll
                                                            ? _c(
                                                                "b-row",
                                                                {
                                                                  staticClass:
                                                                    "mt-3 mb-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    [
                                                                      _c(
                                                                        "b-button",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "success",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.handleClickSelectAll,
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "Select all"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.$screen.width >=
                                                          992
                                                            ? _c("b-table", {
                                                                ref: "myTable",
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                },
                                                                attrs: {
                                                                  "selected-variant":
                                                                    _vm.selectedColor,
                                                                  hover:
                                                                    _vm.hover,
                                                                  responsive:
                                                                    "sm",
                                                                  selectable:
                                                                    "",
                                                                  "select-mode":
                                                                    _vm.selectMode,
                                                                  items:
                                                                    _vm.TsDays,
                                                                  fields:
                                                                    _vm.tsFields,
                                                                  "current-page":
                                                                    _vm.currentPage,
                                                                  "per-page":
                                                                    _vm.perPage,
                                                                  "head-variant":
                                                                    _vm.hv,
                                                                  bordered: "",
                                                                  small: "",
                                                                },
                                                                on: {
                                                                  "row-clicked":
                                                                    _vm.rowClicked,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "cell(isSelected)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                data
                                                                                  .item
                                                                                  .validated ===
                                                                                  0 &&
                                                                                data
                                                                                  .item
                                                                                  .isInContract
                                                                                  ? _c(
                                                                                      "b-form-checkbox",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pj-cb pb-1",
                                                                                        attrs:
                                                                                          {
                                                                                            id: `ts-day-selected-${data.item.id}`,
                                                                                            name: `tsDaySelected${data.item.id}`,
                                                                                            size: "lg",
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.handleChangeTsDaySelected(
                                                                                                $event,
                                                                                                data.item
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              data
                                                                                                .item
                                                                                                .isSelected,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  data.item,
                                                                                                  "isSelected",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "data.item.isSelected",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(dayType)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        data.value
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(encoder)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex flex-row align-items-center justify-content-center",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.encodedBy(
                                                                                          data.item
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(hhmm)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        data.value
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(hhmmOvt)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        data.value
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(hhmmOvt1)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        data.value
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(hhmmOvt2)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        data.value
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(hhmmTransport)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        data.value
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(hhmmNight)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        data.value
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(hhmmTooEarly)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        data.value
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      _vm.showLunchPerDiem() ||
                                                                      _vm.showHotelPerDiem() ||
                                                                      _vm.showDinnerPerDiem() ||
                                                                      _vm.showAbroadPerDiem()
                                                                        ? {
                                                                            key: "cell(lunch)",
                                                                            fn: function (
                                                                              data
                                                                            ) {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex justify-content-center align-items-center",
                                                                                  },
                                                                                  [
                                                                                    data
                                                                                      .item
                                                                                      .totPerDiem >
                                                                                    0
                                                                                      ? _c(
                                                                                          "button",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "b-tooltip",
                                                                                                  rawName:
                                                                                                    "v-b-tooltip.hover.left.html",
                                                                                                  value:
                                                                                                    _vm.tooltipContentPerIdem(
                                                                                                      data.item
                                                                                                    ),
                                                                                                  expression:
                                                                                                    "tooltipContentPerIdem(data.item)",
                                                                                                  modifiers:
                                                                                                    {
                                                                                                      hover: true,
                                                                                                      left: true,
                                                                                                      html: true,
                                                                                                    },
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              _vm.getLucideIcon(
                                                                                                _vm
                                                                                                  .ICONS
                                                                                                  .INFO
                                                                                                  .name
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      _vm
                                                                                                        .ICONS
                                                                                                        .INFO
                                                                                                        .color,
                                                                                                    size: 20,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "adjust-icon-ts-tsd",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.getCurrency(
                                                                                              data
                                                                                                .item
                                                                                                .totPerDiem
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          }
                                                                        : null,
                                                                      {
                                                                        key: "cell(hotel)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex justify-content-center align-items-center",
                                                                              },
                                                                              [
                                                                                data
                                                                                  .item
                                                                                  .totAllowances >
                                                                                0
                                                                                  ? _c(
                                                                                      "button",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "b-tooltip",
                                                                                              rawName:
                                                                                                "v-b-tooltip.hover.left.html",
                                                                                              value:
                                                                                                _vm.tooltipContentAllowance(
                                                                                                  data.item
                                                                                                ),
                                                                                              expression:
                                                                                                "tooltipContentAllowance(data.item)",
                                                                                              modifiers:
                                                                                                {
                                                                                                  hover: true,
                                                                                                  left: true,
                                                                                                  html: true,
                                                                                                },
                                                                                            },
                                                                                          ],
                                                                                        staticClass:
                                                                                          "btn-transparent text-color-rhapsody-in-blue",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Info"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "#225CBD",
                                                                                                size: 16,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "adjust-icon-ts-tsd",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.getCurrency(
                                                                                            data
                                                                                              .item
                                                                                              .totAllowances
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(validatedStatus)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            !_vm.isFilmSingle
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "wrap-status",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        class: `status ${data.item.statusClass}`,
                                                                                        staticStyle:
                                                                                          {
                                                                                            "font-size":
                                                                                              "0.7rem",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                            _vm._s(
                                                                                              data
                                                                                                .item
                                                                                                .validatedStatus
                                                                                            ) +
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(rem)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex justify-content-center align-items-center",
                                                                              },
                                                                              [
                                                                                _vm.isFilmSingle ||
                                                                                data
                                                                                  .item
                                                                                  .validated ==
                                                                                  0 ||
                                                                                data
                                                                                  .item
                                                                                  .validated ==
                                                                                  4 ||
                                                                                data
                                                                                  .item
                                                                                  .validated ==
                                                                                  16
                                                                                  ? _c(
                                                                                      "button",
                                                                                      {
                                                                                        staticClass:
                                                                                          "btn-transparent text-color-rhapsody-in-blue",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .TRASH
                                                                                              .name
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .ICONS
                                                                                                    .TRASH
                                                                                                    .color,
                                                                                                size: 20,
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.delItem(
                                                                                                    data
                                                                                                      .item
                                                                                                      .id
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.getRowCount(_vm.TsDays) <
                                                  1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "empty-state",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  42,
                                                                  "No timesheet to send"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.getRowCount(_vm.TsDays) > 0
                                            ? _c(
                                                "nav",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-end",
                                                },
                                                [
                                                  !_vm.isFilmSingle
                                                    ? _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "clearfix valid-days-mobile-floating-button",
                                                              attrs: {
                                                                xl: "12",
                                                              },
                                                            },
                                                            [
                                                              !_vm.disableSendData()
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      class: `${
                                                                        _vm
                                                                          .$screen
                                                                          .width <
                                                                        768
                                                                          ? "w-100"
                                                                          : ""
                                                                      }`,
                                                                      attrs: {
                                                                        size: "md",
                                                                        variant:
                                                                          "success",
                                                                        disabled:
                                                                          _vm
                                                                            .tsDaysSelected
                                                                            .length ===
                                                                          0,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.SendDataForValidation,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              43,
                                                                              "Send not submitted days for validation"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }